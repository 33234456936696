<template>
  <div class="UI_view">
    <div class="zhaomu" @click="showPartnerView">
      <img src="../assets/img/zm1.png">
      <span>招募潮品合伙人</span>
    </div>

    <div class="good_price" :style="{ background: font_back_color }">
      <img :src="goodIcon">
      <span :style="{ color: font_back_contrary }">￥29880</span>
    </div>

    <div class="menu">

    </div>


    <div class="top-Page" @click="showTopPage">
      <div :style="{ color: font_back_contrary }">
        查看更多
      </div>
    </div>

    <div class="ar">
      <img class="ar_icon" @click="goAR" :src="arIcon">
      <span :style="{ color: font_back_color }">AR体验</span>
    </div>

    <div class="music">
      <img class="music_icon" @click="ctrMusic" :src="musicIcon">
      <span :style="{ color: font_back_color }">背景音乐</span>
      <video id="homemusic" hidden="hidden" autoplay="autoplay" loop="loop">
        <source id="mp4_src" src="@/assets/Cinematic.mp3" type="video/mp4">
      </video>
    </div>

    <div class="light">
      <img class="light_icon" @click="setlight" :src="lightIcon">
      <span :style="{ color: font_back_color }">夜光</span>
    </div>

    <div class="reset" @click="reset">
      <div>
        <img :src="resetIcon">
      </div>
    </div>

    <div id="menu">
      <div>
        <img @click="showList" style="height:28px;width:28px;" :src="menu">
      </div>
      <ul id="list" class="list">
        <li class="list_button" @click="watch_dial">表盘<div class=""></div>
        </li>
        <li class="list_button" @click="watch_buckle">表扣<div class=""></div>
        </li>
        <li class="list_button" @click="watch_band">表带<div class=""></div>
        </li>
      </ul>
    </div>
    <PagCanvas @getPagList="getPagList" ref="PagChild" />
    <TopDrawer />

  </div>
</template>

<script>
import musicIcon1_1 from "../assets/img/music.png";
import musicIcon1_2 from "../assets/img/music2.png";
import musicIcon2_1 from "../assets/img/night/music.png";
import musicIcon2_2 from "../assets/img/night/music.png";

import menu1 from "../assets/img/menu1.png";
import menu2 from "../assets/img/menu2.png";

import light_1 from "../assets/img/light.png";
import light_2 from "../assets/img/night/light.png";

import ar_1 from "../assets/img/ar.png";
import ar_2 from "../assets/img/night/ar.png";

import reset_1 from "../assets/img/reset.png"
import reset_2 from "../assets/img/night/reset.png";

import good_1 from "../assets/img/good.png";
import good_2 from "../assets/img/night/good.png";

import topBack_1 from "../assets/img/topbtbg.png";
import topBack_2 from "../assets/img/night/topbtbg.png";


import { cameraControls } from "../modules/controls/orbitController";

import PagCanvas from "./PagCanvas.vue";
import TopDrawer from './Top_Drawer.vue'

import bdmp4 from '../assets/MP4/bd.mp4'
import bkmp4 from '../assets/MP4/bk.mp4'
import yymp4 from '../assets/MP4/yy.mp4'


import {
  RGBELoader
} from 'three/addons/loaders/RGBELoader.js';
import {
  render
} from "@/modules/init/renderer.js";
import * as THREE from 'three';
import { is } from "@babel/types";

var percentageInterval = null
var morning_texture;
var isMorning = true;
var li;

export default {
  name: 'UIView',
  components: {
    PagCanvas,
    TopDrawer
  },
  props: {
    msg: String
  },
  data() {
    return {
      menu: menu1,
      musicIcon: musicIcon1_2,
      lightIcon: light_1,
      arIcon: ar_1,
      resetIcon: reset_1,
      goodIcon: good_1,
      topBack: topBack_1,
      font_back_color: '#000',
      font_back_contrary: '#fff',
      loadingComplete: true,
      isAR: false,
      cameraControls: cameraControls,
      percentageNum: 10,
      pagList: new Array(),
      videoSrc: { bdmp4, bkmp4, yymp4 },
      visibility: false
    }
  },
  mounted() {
    li = document.getElementById('li');
  },
  methods: {
    getPagList(childData) {
      this.pagList = childData;
      console.log(childData)
    },
    showPartnerView() {
      document.getElementsByClassName('partners-wrap')[0].className = 'partners-wrap animate__animated animate__fadeIn'
      document.getElementsByClassName("partners-show")[0].style.cssText = "display:inline";
    },

    ctrMusic() {

      if (this.musicIcon == musicIcon1_1) {
        document.getElementById('homemusic').pause();
        this.musicIcon = musicIcon1_2
      } else {
        document.getElementById('homemusic').play()
        this.musicIcon = musicIcon1_1
      }

    },

    reset() {
      console.log(2)
      window.disableAutoRotate = true;
      cameraControls.reset(true);
    },
    setlight() {
      // alert('天黑了');
      // let rGBELoader = new RGBELoader().setPath('./static/hdr/');
      // rGBELoader.load(`DH-314LP.hdr`, function (texture) {
      //   texture.mapping = THREE.EquirectangularReflectionMapping;
      //   texture.dispose();
      // })
      // if (bool)  window.scene.environment = morning_texture;
      if (isMorning) {
        if (!morning_texture) morning_texture = window.scene.environment;
        window.scene.environment = '';
        document.getElementById('canvas-frame').style.backgroundImage = 'url(' + require('../assets/night.jpg') + ')'
        this.changeBt(isMorning);
        isMorning = false;
        dy.material.emissiveIntensity = 1;
        // let timer = setInterval(function () {
        //   dy.material.emissiveIntensity += 0.1;
        //   if (dy.material.emissiveIntensity >= 1) {
        //     clearInterval(timer);
        //   }
        // }, 250);
      }
      else {
        window.scene.environment = morning_texture;
        document.getElementById('canvas-frame').style.backgroundImage = 'url(' + require('../assets/3Dbg.png') + ')'
        dy.material.emissiveIntensity = 0;
        this.changeBt(isMorning);
        isMorning = true;
        console.log(2)
      }

      render();


    },
    changeBt(bool) {
      if (bool) {
        this.menu = menu2;
        this.musicIcon = musicIcon2_1;
        this.lightIcon = light_2;
        this.arIcon = ar_2;
        this.resetIcon = reset_2;
        this.goodIcon = good_2;
        this.topBack = topBack_2;
        this.font_back_color = "#fff";
        this.font_back_contrary = "#000"
        document.getElementsByClassName('top-Page')[0].children[0].style.background = 'url(' + require('../assets/img/night/topbtbg.png') + ')'
        document.getElementsByClassName('top-Page')[0].children[0].style.backgroundSize = '100% 100%'
      } else {
        this.menu = menu1;
        this.musicIcon = musicIcon1_1;
        this.lightIcon = light_1;
        this.arIcon = ar_1;
        this.resetIcon = reset_1;
        this.goodIcon = good_1;
        this.topBack = topBack_1;
        this.font_back_color = "#000";
        this.font_back_contrary = "#fff"
        document.getElementsByClassName('top-Page')[0].children[0].style.background = 'url(' + require('../assets/img/topbtbg.png') + ' )'
        document.getElementsByClassName('top-Page')[0].children[0].style.backgroundSize = '100% 100%'
      }
    },
    showList() {
      if (document.getElementById('list').className == 'list') {
        document.getElementById('list').className = 'list_show';
        document.getElementById('menu').style.background = "rgba(0, 0, 0, 1)";
        this.menu = menu1;
      } else {
        document.getElementById('list').className = 'list'
        document.getElementById('menu').style.background = "rgba(255, 255, 255, 0)";
        this.menu = menu2;
      }
    },
    watch_dial(e) {
      this.hideAllcanvas(e.target, 'yymp4');
      this.showBP(0);
    },
    watch_buckle(e) {
      this.hideAllcanvas(e.target, 'bkmp4');
      this.showBK();
    },
    watch_band(e) {
      this.hideAllcanvas(e.target, 'bdmp4');
      this.showBD();
    },

    hideAllcanvas(target, show_Name, num) {

      // show ListButton
      for (let i of document.getElementsByClassName('list_button'))
        i == target ? target.children[0].className = 'list_active' : i.children[0].className = ''

      setTimeout(function () { document.getElementsByClassName('pag-wrap')[0].style.display = 'inline' }, 500);
      // show canvas
      // for (let i of document.getElementsByClassName('canvas')) {
      //   i.style.display = 'none'
      // }
      // document.getElementById(show_Name).style.display = 'inline';

      // 切掉3D
      // window.scene.visible=false;
      // document.getElementById('canvas-frame').children[0].style.display='none'

      document.getElementsByTagName("video")[1].src = this.videoSrc[show_Name];
      document.getElementsByTagName("video")[1].play();


      let vo = document.getElementsByClassName("video")[0]
    },
    showTopPage() {
      li.style.top = '-0px';
      document.getElementsByClassName('tui-bg')[0].style.display = '';
    },
    showBP(num) {
      //show BP list
      document.getElementsByClassName("bp-list")[0].style.display = 'inline';
      let bpList = document.getElementsByClassName("bp-list")[0].children;
      for (let i = 0; i < bpList.length; i++)
        bpList[i].className = i == num ? "bp-item bp-active" : "bp-item";

      this.$refs.PagChild.changeText(0)

    },
    showBK() {
      //show BK list
      document.getElementsByClassName("bp-list")[0].style.display = 'none';
      this.$refs.PagChild.changeText(1);

    },
    showBD() {
      document.getElementsByClassName("bp-list")[0].style.display = 'none';
      this.$refs.PagChild.changeText(2);

    },
    goAR() {
      if (this.isAR) {
        this.closeCamera();
        this.isAR = false;
        document.getElementById('camera_v').setAttribute('height', `0%`);
        document.getElementById('canvas-frame').style.backgroundImage =isMorning? 'url(' + require('../assets/3Dbg.png') + ') ' :
        'url(' + require('../assets/night.jpg') + ')'
        document.getElementById('canvas-frame').style.backgroundSize = '100% 100%' 
      }
      else {
        this.openCamera();
        this.isAR = true;
        document.getElementById('canvas-frame').style.background = 'none'
      }
      return;
      window.location = 'https://app.duwushoufa.com/ar'
    },

    closeCamera() {
      let videoElement = document.getElementById('camera_v');
      videoElement.srcObject.getTracks().forEach(track => {
        track.stop();
      });
    },
    openCamera(constraints) {
      let videoElement = document.getElementById('camera_v');
      videoElement.style.height = '100vh'
      document.getElementById('canvas-frame').style.background = 'none'
      videoElement.setAttribute('playsinline', 'playsinline');
      // 如果已打开摄像头，则需要先关闭。
      // this.closeCamera();
      navigator.mediaDevices.getUserMedia({
        audio: false, video: { facingMode: { exact: 'environment' } }
      }).then(stream => {
        videoElement.srcObject = stream;
        videoElement.style.display = 'block';
        videoElement.play().then(() => {
        }).catch(err => {
          console.error(`摄像头视频绑定失败\n${err}`);
          console.log('no');
        });
        videoElement.onloadedmetadata = () => {
          const cameraSize = {
            width: window.innerWidth,
            height: window.innerHeight
          };
          console.info(`camera size ${JSON.stringify(cameraSize)}`);
          // 简单处理横/竖屏
          videoElement.setAttribute('height', `100vh`);
          console.log('ok');
        };
      }).catch(err => {
        console.log(err);
      });
    },


    async getWathBand() {
      console.log(123)
      // 实例化 PAG
      PAG = await window.libpag.PAGInit();
      // 获取 PAG 素材数据
      const buffer = await fetch("./static/pag/bdv01.pag").then((response) => response.arrayBuffer());
      // 加载 PAG 素材为 PAGFile 对象
      const pagFile = await PAG.PAGFile.load(buffer);
      // 将画布尺寸设置为 PAGFile的尺寸
      canvas = document.getElementById('pag');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      // 实例化 PAGView 对象
      pagView = await PAG.PAGView.init(pagFile, canvas);
      // 播放 PAGView
      await pagView.play();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.UI_view {
  z-index: 8;
}

.zhaomu {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  left: 0;
  top: 7%;
  width: 143px;
  height: 34px;
  background: url('../assets/img/zmbg.png') no-repeat;
  background-size: 143px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.zhaomu:active {
  background: url('../assets/img/zmbg_hover.png') no-repeat;
}

.zhaomu img {
  margin-right: 5px;
  width: 14px;
  height: 14px;
}


.good_price {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  left: 0px;
  bottom: 10%;
  width: 109px;
  height: 42px;
  background: #000000;
  border-radius: 0px 57px 57px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.good_price img {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.top-Page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-Page div {
  width: 110px;
  height: 22px;
  background: url(../assets/img/topbtbg.png);
  background-size: 100% 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  font-size: 10px;
  color: #D8D8D8;
  text-align: center;
}


.ar {
  position: absolute;
  right: 0%;
  bottom: 25%;
  width: 100px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0);
}

.ar:active {
  background: rgba(255, 255, 255, 0);
}

.ar img {
  width: 26px;
  margin-bottom: 5px;
}

.music {
  position: absolute;
  right: 0%;
  bottom: 15%;
  width: 100px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0);
}

.music:active {
  background: rgba(255, 255, 255, 0);
}

.music img {
  width: 26px;
  margin-bottom: 5px;
}


.light {
  position: absolute;
  right: 0%;
  bottom: 5%;
  width: 100px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.light img {
  width: 26px;
}

.reset {
  position: absolute;
  right: 50%;
  bottom: 22%;
  width: 26px;
  color: #000;
}

.reset img {
  position: absolute;
  width: 26px;
}


/* 下拉菜单 */
li {
  list-style: none;
  height: 28px;
  color: #fff;
  margin: 14px 0;
  padding-bottom: 2px;
  /* text-decoration:underline */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

li .list_active {
  width: 20px;
  border-bottom: 2px solid;
  text-align: center;
  margin-top: 4px;
}

#menu {
  position: absolute;
  right: 5%;
  top: 7%;
  display: inline-block;
  background: rgba(255, 255, 255, 0);
  border-radius: 59px 59px 59px 59px;
  padding: 10px 0;
  opacity: 0.4;
}

#menu .list {
  max-height: 0;
  transition: max-height 0.2s ease-in, opacity 0.1s ease-out;
  overflow: hidden;
  width: 45px;
  margin: 0;
  padding: 0;
  text-align: center;
  opacity: 0;
}

#menu .list_show {
  max-height: 200px;
  transition: max-height 0.2s ease-in, opacity 0.1s ease-in;
  width: 45px;
  margin: 0;
  padding: 0;
  opacity: 1;
}

.button {
  height: 32px;
  width: 45px;
  margin-top: 6px;
  border-radius: 4px;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
  line-height: 32px;
  background: #E33E33;
  text-align: center;
}

.isShow {
  display: inline;
}

/* 下拉菜单 END*/
</style>
