import { addToUpdate } from '../init/update.js';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { DDSLoader } from "three/examples/jsm/loaders/DDSLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { Mesh, Clock, AnimationMixer } from 'three';
// -----------------------------------------

// 加载模型
function loadModel (url, type, onSuccess, onProgress, onError) {
	switch (type) {
		case "gltf": gltfModelLoader(url, onSuccess, onProgress, onError); break;
		case "glb": gltfModelLoader(url, onSuccess, onProgress, onError); break;
	}
}

// -----------------------------------------

// gltf 和 glb 加载器
function gltfModelLoader (url, onSuccess, onProgress, onError) {
	let gltfLoader = new GLTFLoader();
	let dracoLoader = new DRACOLoader();
	let ddsLoader = new DDSLoader();
	dracoLoader.setDecoderPath('static/gltf/');
	gltfLoader.setDRACOLoader(dracoLoader);
	gltfLoader.load(url, gltf => {
		onSuccess(gltf.scene);
		setAnimation(gltf.scene, gltf.animations);
	}, onProgress, onError);
}

// -----------------------------------------

// 动画加载
function setAnimation (object3d, _animations) {
	let clock = new Clock();
	let animations = object3d.animations || _animations;
	if (animations && animations.length) {
		let mixer = new AnimationMixer(object3d);
		animations.forEach(animation => {
			let action = mixer.clipAction(animation);
			action.play();
		});
		addToUpdate(() => { mixer ? mixer.update(clock.getDelta() / 1.5) : null });
	}
}

// -----------------------------------------

// 清理模型内存
function clearMemory (scene) {
	if(!scene) return;
	scene.traverse(item => {
		if (item instanceof Mesh) {
			item.geometry.dispose();
			item.material.dispose();
		}
	});
}

// -----------------------------------------

export { loadModel, clearMemory };
