import {
    addToUpdate,
    removeInUpdate
} from '../init/update.js';
import * as THREE from 'three';
import * as CameraControls from '../threejs/examples/jsm/controls/camera-controls.js';

var cameraControls;
var clock;
// ----------------- 摄像机控制器 -------------------
window.disableAutoRotate = true;

function initCameraControls(renderer, camera, {
    maxPolarAngle,
    minPolarAngle,
    maxDistance,
    minDistance,
    truckSpeed,
    minAzimuthAngle,
    maxAzimuthAngle
}) {
    CameraControls.install({
        THREE: THREE
    });
    cameraControls = new CameraControls(camera, renderer.domElement);
    window.cameraControls = cameraControls;
    clock = new THREE.Clock();
    // cameraControls.maxPolarAngle = maxPolarAngle;
    // cameraControls.minPolarAngle = minPolarAngle;
    cameraControls.maxDistance = 160;
    cameraControls.minDistance = 50;
    // cameraControls.truckSpeed = truckSpeed;
    // if(minAzimuthAngle){
    // cameraControls.minAzimuthAngle = minAzimuthAngle;
    // cameraControls.maxAzimuthAngle = maxAzimuthAngle;
    // }
    addToUpdate(cameraCtrUpdate);
    // // 设置缓冲系数 缓冲效果
    // cameraControls.enableDamping = false; // controls.dampingFactor = 0.04;
    cameraControls.azimuthAngle = 0.5;
    cameraControls.truckSpeed = 0;

    cameraControls.addEventListener('controlstart', () => {

        window.disableAutoRotate = true;

    });
}

// ----------------- 每帧执行控制 ---------------------

function cameraCtrUpdate() {
    const delta = clock.getDelta();
    const updated = cameraControls.update(delta);
    if (!window.disableAutoRotate) {
        cameraControls.azimuthAngle += 20 * delta * THREE.MathUtils.DEG2RAD;
    }
}

function setTolookAt() {
    cameraControls.rotate(360 * THREE.MathUtils.DEG2RAD, 0, true);
}

function setLookAt(a, b, c, d, e, f) {
    cameraControls.setLookAt(a, b, c, d, e, f, true);
}

// -------------------------------------------------

export {
    setLookAt,
    setTolookAt,
    cameraControls,
    initCameraControls
};