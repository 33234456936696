import { mouse } from './mouse.js';
import {
    Raycaster,
    TextureLoader,
    MeshBasicMaterial,
    Mesh,
    CircleBufferGeometry,
    RepeatWrapping,
    DoubleSide,
    Vector3
} from 'three';

var raycaster = new Raycaster();
raycaster.far=1000;
var timer;
var intersects;
var mouseIntervalTime = 16;
var intersection = null;
var mousePoint;
var intersectArr = [];
var normalOrder;

// ----------------------- 添加射线可交互的物体 -----------------------

function addToIntersect (object) {
    intersectArr.push(object);
}

// ---------------------------- 初始化射线 ----------------------------

function initRaycaster (element) {
    let canvas_frame = document.querySelector(element);
    canvas_frame.addEventListener('mouseup', () => { intersection = null; });
    canvas_frame.addEventListener('touchend', () => { intersection = null; });
}

// ----------------------------- 更新射线 -----------------------------

function updateRaycaster (camera, model) {
    if (!model) return;
    // if (timer) return;
    raycaster.setFromCamera(mouse, camera);
    intersects = raycaster.intersectObjects([ ...intersectArr], true);
    intersection = intersects.length > 0 ? intersects[0] : null;
    timer = setTimeout(() => {
        timer = null;
    }, mouseIntervalTime);
}

// ---------------------------- 初始化鼠标点 ---------------------------

function initDrawMousePoint (element, scene, m_normalOrder, scale) {
    let map = new TextureLoader().load('./queqian.png');
    map.wrapS = map.wrapT = RepeatWrapping;
    map.anisotropy = 16;
    let material = new MeshBasicMaterial({ transparent: true, map: map, side: DoubleSide, depthTest: false });
    let geometry = new CircleBufferGeometry(0.3, 36, 0, Math.PI * 2);
    mousePoint = new Mesh(geometry, material);
    if(scale)mousePoint.scale.set(scale,scale,scale)
    scene.add(mousePoint);
    setTimeout(() => {
        mousePoint.position.set(0, -100000, 0);
    }, 0);
    let canvas_frame = document.querySelector(element);
    canvas_frame.addEventListener('mousemove', updateMousePoint);
    // 设置法线顺序
    normalOrder = m_normalOrder;
}

// ----------------------------- 更新鼠标点 -----------------------------

function updateMousePoint () {
    if (!intersection) {
        mousePoint.position.set(0, -1000, 0);
        return;
    }
    mousePoint.position.set(0, 0, 0);
    let dir;
    switch (normalOrder) {
        case "XYZ": dir = new Vector3(intersection.face.normal.x, intersection.face.normal.y, intersection.face.normal.z); break;
        case "XZY": dir = new Vector3(intersection.face.normal.x, -intersection.face.normal.z, intersection.face.normal.y); break;
        default: dir = new Vector3(intersection.face.normal.x, -intersection.face.normal.z, intersection.face.normal.y); break;
    }
    mousePoint.lookAt(dir);
    mousePoint.position.copy(intersection.point);
    // console.log("pos: ", mousePoint.position.x.toFixed(2), (mousePoint.position.y + 1.31).toFixed(2), mousePoint.position.z.toFixed(2))
    // console.log("pointRot: ", mousePoint.rotation.x.toFixed(2), mousePoint.rotation.y.toFixed(2), mousePoint.rotation.z.toFixed(2))
    // console.log("----------------------------------")
}

// ---------------------------------------------------------------------

export { initRaycaster, initDrawMousePoint, raycaster, intersection, addToIntersect ,updateRaycaster };
