
// -------------------- 初始化自适应浏览器窗口大小 ---------------------

function initResizeScreen (element, renderer, camera) {
    window.onresize = function () {
        let canvas_frame = document.querySelector(element);
        let width = window.innerWidth;
        let height = window.innerHeight;
        console.log(height)
        renderer.setSize(width, height);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
    }
}

// -------------------------------------------------------------------

export { initResizeScreen };