import { Vector2 } from 'three';
import { updateRaycaster } from './raycaster';

var mouse = new Vector2();

// -------------------- 初始化鼠标事件 --------------------

function initMouse (element, camera, model) {
    let canvas_frame = document.querySelector(element);

    canvas_frame.addEventListener('mousemove', (e) => { onDocumentMouseMove(e, camera, model); });
    canvas_frame.addEventListener( 'mousedown', onMouseDown, false );
    canvas_frame.addEventListener( 'mousewheel', onMouseWheel, false );
    canvas_frame.addEventListener("mouseup", onMouseUp);
    // canvas_frame.removeEventListener( 'MozMousePixelScroll', onMouseWheel, false ); // firefox

    canvas_frame.addEventListener( 'touchstart', onTouchStart, false );
    canvas_frame.addEventListener( 'touchend', onTouchEnd, false );
    canvas_frame.addEventListener( 'touchmove', onTouchMove, false );

    // document.removeEventListener( 'mousemove', onMouseMove, false );
    // document.removeEventListener( 'mouseup', onMouseUp, false );

}



//-----------------
// 鼠标事件
//-----------------


// --------------------- 当鼠标移动时 ---------------------

let basic_navigation = document.getElementsByClassName('basic_navigation');

function onDocumentMouseMove (event, camera, model) {
    event.preventDefault();
    mouse.x = ((event.clientX-basic_navigation[0].scrollWidth/2) / window.innerWidth) * 2 - 1;
    mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

    //场景鼠标滑动事件事件   
    updateRaycaster(camera, model); // 射线检测事件
}


// --------------------- 当鼠标点击时 ---------------------
function onMouseDown (event) {
    
}

// --------------------- 当鼠标点击时---------------------
function onMouseUp (event) {
    let bottom_Text = document.getElementsByClassName('bottom_Text')[0]
    if(bottom_Text){
        bottom_Text.style.display='none';
    }
}

// --------------------- 当鼠标滚动时 ---------------------
function onMouseWheel (event) {}


//-----------------
// 手势事件
//-----------------

// --------------------- 手势触发 ---------------------

function onTouchStart (event) {
    
}


// --------------------- 手势结束 ---------------------

function onTouchEnd (event) {
    
}

// --------------------- 手势滑动 ---------------------

function onTouchMove (event) {
    
}


// --------------------------------------------------------

export { initMouse, mouse };
