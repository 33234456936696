import { AmbientLight, DirectionalLight, PointLight } from 'three';

// ---------------------- 初始化灯光 ------------------------

function initLight (scene, lights = []) {
    lights.forEach(light => {
        let tempLight = null;
        switch (light.type) {
            case "AmbientLight": tempLight = creatAmbientLight(light); break;
            case "DirectionalLight": tempLight = creatDirectionalLight(light); break;
            case "PointLight": tempLight = creatPointLight(light); break;
            default: console.error("灯光 type 设置错误！"); break;
        }
        scene.add(tempLight);
    });
}

// ---------------------- 创建环境光 --------------------------

function creatAmbientLight ({ color, intensity }) {
    return new AmbientLight(color, intensity);
}

// ---------------------- 创建平行光 --------------------------

function creatDirectionalLight ({ color, intensity, pos }) {
    let light = new DirectionalLight(color, intensity);
    light.position.copy(pos);
    return light;
}

// ---------------------- 创建点光源 --------------------------

function creatPointLight ({ color, intensity, distance, pos }) {
    let light = new PointLight(color, intensity, distance);
    light.position.copy(pos);
    return light;
}

// -----------------------------------------------------------

export { initLight };
