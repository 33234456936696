// 初始化
import {
    initRenderer,
    render
} from "@/modules/init/renderer.js";
import {
    initCamera,
    setCameraInitPos
} from "@/modules/init/camera.js";
import {
    initScene
} from "@/modules/init/scene.js";
import {
    initResizeScreen
} from "@/modules/init/resizeScreen.js";
import {
    initLight
} from '@/modules/init/light.js';

// 效果类
import {
    initBackground
} from '@/modules/effects/background.js';

// 加载器
import {
    loadModel
} from '@/modules/loaders/modelLoader.js';

// 控制器
import {
    initCameraControls,
    cameraControls
} from "@/modules/controls/orbitController.js";
import {
    initRaycaster
} from "@/modules/common/raycaster.js";

// 工具类
import {
    addToUpdate
} from '../init/update.js';
import {
    RGBELoader
} from 'three/addons/loaders/RGBELoader.js';

import {
    UnsignedByteType,
    PMREMGenerator,
    Color
} from 'three';
import * as THREE from 'three';

// 渲染器
var renderer;
// 摄像机
var camera;
// 场景
var scene;
// 模型
var model1;
var model2;
var model3;

// ---------------------- 当得到 JSON 数据后 ---------------------------

function initModel() {

    window.scene = scene;
    // 初始化渲染器
    renderer = initRenderer("#canvas-frame", "sRGBEncoding");
    // 初始化场景
    scene = initScene();
    // 初始化摄像机
    camera = initCamera("#canvas-frame", {
        fov: 60,
        near: 0.1,
        far: 450
    });
    // 设置摄像机初始位置
    setCameraInitPos(camera, {
        x: 0,
        y: 8,
        z: 90
    });
    // 屏幕自动适配
    initResizeScreen("#canvas-frame", renderer, camera);
    // 实时渲染
    render(renderer, camera, scene);

    // 初始化灯光
    initLight(scene, [{
            type: "AmbientLight",
            color: "#AAAAAA",
            intensity: 0.3
        },
        {
            type: "DirectionalLight",
            color: "#8F866E",
            intensity: 0,
            pos: {
                x: 200,
                y: 100,
                z: 200
            }
        },
        {
            type: "DirectionalLight",
            color: "#DDDDDD",
            intensity: 0,
            pos: {
                x: -200,
                y: 100,
                z: 200
            }
        },
        {
            type: "DirectionalLight",
            color: "#EEEEEE",
            intensity: 0,
            pos: {
                x: 0,
                y: 0,
                z: -200
            }
        }
    ]);

    // 初始化天空盒
    // 
    // DH-314LP
    initBackground({
        renderer,
        scene,
        type: "hdr",
        hdrName: 'kloofendal_38d_partly_cloudy_puresky_2k',
        isHdrEffectMaterial: true,
        envName: 'DH-314LP',
        camera
    });

    // 加载整体场景模型
    loadModel(
        // "static/model/doir.glb",
        "static/model/file/GLTF-ShouBiao3.gltf",
        "gltf",
        object3d => {
            onSuccess(object3d, scene);
            model1 = object3d;
            // 实时得到鼠标相关数值
            // initMouse("#canvas-frame", camera, model1);
            // 初始化射线
            console.log(object3d)
            window.object3d = object3d
            let rGBELoader = new RGBELoader().setPath('./static/hdr/');
            rGBELoader.load(`royal_esplanade_1k.hdr`, function (texture) {
                texture.mapping = THREE.EquirectangularReflectionMapping;
                object3d.traverse(function (obj) {
                    if (obj.name == 'MW2017L002') {
                        obj.material.depthWrite = false;
                    } else if (obj.name == 'BoLi') {
                        obj.material.depthWrite = false;
                        // console.log('boli')
                        // console.log(obj)
                    } else if (obj.name == 'DuYou') {
                        window.dy = obj;
                        obj.material.emissiveIntensity = 0; // 1 
                        obj.material.envMapIntensity = 0.8; // 1 
                    } else if (obj.name == 'zuanshi') {
                        // console.log(obj)
                        // royal_esplanade_1k
                        obj.material.envMap = texture;
                        texture.dispose();
                        render();
                    } else if (obj.name == 'ZUANSHIZU') {
                        obj.traverse(function (zs) {
                            if (zs.type == 'Mesh') {
                                zs.material.color.r = 0.5;
                                zs.material.color.g = 0.5;
                                zs.material.color.b = 0.5;
                                zs.material.envMap = texture;
                            }
                        })
                    }
                })
                texture.dispose();
                render();

                initRaycaster("#canvas-frame");
                document.getElementsByClassName('loading-wrap')[0].style.display = 'none';
                // document.getElementById('homemusic').play();
                setTimeout(() => {
                    cameraControls.rotate(360 * THREE.MathUtils.DEG2RAD, 0, true)
                }, 1000);
                setTimeout(() => {
                    window.disableAutoRotate = false;
                }, 2000);
            });

        },
        onProgress,
        onError
    );

    // 加载控制
    initCameraControls(renderer, camera, {
        maxPolarAngle: Math.PI,
        minPolarAngle: -Math.PI,
        maxDistance: 100,
        minDistance: 75,
        truckSpeed: 0,
        minAzimuthAngle: -Math.PI,
        maxAzimuthAngle: Math.PI
    });
}

// -----------------------------------------------

// 当加载成功时
function onSuccess(object3d, scene) {
    scene.add(object3d);
}

// 加载中
function onProgress(xhr) {
    let progress = Math.floor((xhr.loaded / xhr.total) * 100);
}

// 加载出错时
function onError(e) {
    console.log(e);
}

// -----------------------------------------------

export {
    initModel,
    renderer,
    camera,
    scene
};