<template>
  <div>
    <div id="web_show">
      <!-- 显示区 -->
      <div id="display" class="display" style="z-index:1">
        <div id="canvas-frame">
          <video id="camera_v"></video>
        </div>
      </div>
      <Loading />
      <Partners />
      <UIView />
    </div>
  </div>
</template>

<script>
import { initModel } from "@/modules/common/executionOrder.js";
import Loading from "./Loading.vue";
import UIView from "./UIView.vue";
import Partners from "./Partners.vue";

export default {
  name: 'watch3D',
  components: {
    Loading,
    UIView,
    Partners
  },
  props: {
    msg: String
  },
  data() {
    return {
      loadingComplete: false,
    }
  },
  mounted() {
    // 加载场景
    initModel()
    // setTimeout(function () { initModel() }, 1000);
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

#web_show {}

#canvas-frame {
  background: url('../assets/3Dbg.png')no-repeat;
  /* background: url('../assets/night.jpg')no-repeat; */
  background-size: 100% 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

#camera_v {
  position: fixed;
  right: 0px;
  bottom: 0px;
  min-width: 100%;
  min-height: 100%;
  height: auto;
  width: auto;
  background: #fff;
  z-index: -1;
}
</style>
