import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import { UnsignedByteType, PMREMGenerator, Color,EquirectangularReflectionMapping } from 'three';
import { render } from '../init/renderer'

// ------------------------ 初始化背景 -------------------------------

function initBackground ({renderer = null, scene = null, type = 'color', color = 0x141414, hdrName = "tiankong1", isHdrEffectMaterial = false, envName = false,camera= null }) {
    switch (type) {
        case 'color': solidColor(scene, color); break;
        case 'hdr': hdrEnvmap(renderer, scene, hdrName, isHdrEffectMaterial,envName,camera); break;
    }
}

// ------------------------- 纯色背景 --------------------------------

function solidColor (scene, color) {
    scene.background = new Color(color);
}

// ----------------------- HDR 全景图背景 ----------------------------

function hdrEnvmap (renderer, scene, hdrName, isHdrEffectMaterial, envName,camera) {
    let pmremGenerator = new PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();
    let rGBELoader = new RGBELoader().setPath('./static/hdr/');
    
    rGBELoader.load(`${hdrName}.hdr`, function (texture) {
        texture.mapping = EquirectangularReflectionMapping;
        // let envMap = pmremGenerator.fromEquirectangular(texture).texture;
        // scene.background = envMap;
        scene.environment = isHdrEffectMaterial ? texture : null;
        texture.dispose();
        render();
    });
    if(envName){
        return;
        rGBELoader.load(`${envName}.hdr`, function (texture) {
            let envMap = pmremGenerator.fromEquirectangular(texture).texture;
            scene.environment = envMap;
            console.log(scene)
            let group = scene.children[4]
            console.log(group)
            // group.traverse(function (obj) {
            //     if(obj.name == 'zuanshi'){
            //         console.log(obj)
            //     }
            // })
            texture.dispose();
            pmremGenerator.dispose();
        });
    }
}

// -------------------------------------------------------------------

export { initBackground };
