<template>
  <!-- 顶部抽屉页面 -->
  <div class="tui">

    <!-- <div id="" class="flex center bottom-nav fixed" style="z-index: 1;">

      <div class="tanchu button" @click="show">弹出</div>
    </div> -->
    <div class="tui-bg" @click="show" style="display:none"></div>
    <div id="li" class="flex center fixed column bottom-nav">
      <div class="li-group">
        <img src="../assets/img/logoText.png" style="width:85px;margin-bottom:30px" />
        <div class="text1">潮品腕表</div>
        <div class="text2">梦幻舞台系列腕表</div>

        <div class="text3" @click="show">关闭详情</div>
      </div>
    </div>
  </div>

</template>

<script>

var li;
export default {
  name: 'TopDrawer',
  props: {
    msg: String,
  },
  data() {
    return {
      loadingComplete: true,
      visibility: false
    }
  },
  mounted() {
    li = document.getElementById('li');
  },
  methods: {
    show() {
      li.style.top = '-220px';
      document.getElementsByClassName('tui-bg')[0].style.display = 'none';
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  margin: 0 !important;
}

.tui {
  width: 100%;
  height: 100%;
  position: relative;
  /* font-size:16px; */
}


.tui .flex {
  display: flex;
}

.tui .flex .item {
  flex: 1;
}

.tui .column {
  flex-direction: column;
}

.tui .fixed {

  position: fixed;
}

.tui .bottom-nav {
  width: 100%;
  bottom: 0;
}



.tui .center {
  justify-content: center;
}

.tui .button {
  text-align: center;
  color: #fff;
  line-height: 40px;
  background: #007AFF;
  border-radius: 3px;
  border: #f9f9f9 solid 1px;
  /* padding:5px; */

}

.tui .button:hover {
  opacity: 0.6;
  /* background: rgba(0,0,0,0.5); */
}

.tui .tanchu {
  width: 100%;
}

#li {
  /* box-sizing: border-box; */
  top: -220px;
  height: 220px;
  bottom: 0px;
  /* background: rgba(0, 0, 0, 0.1); */
  transition: top 1s;
  -moz-transition: top 1s;
  -webkit-transition: top 1s;
  -o-transition: top 1s;
  background: url('../assets/img/topbg.png') 100% 100%;
  background-size: cover;
  background-repeat: no-repeat no-repeat;
  color: #fff;
}

.li-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text1 {
  font-size: 33px;
  font-weight: 500;
  margin-bottom: 10px;
}

.text2 {
  font-size: 24px;
  font-weight: 300;
  line-height: 22px;
}

.text3 {
  font-size: 12px;
  font-weight: 300;
  margin-top: 20px;
}

.tui-bg {
  width: 100%;
  height: 100vh;
  background: #0000001a;
}
</style>
