import {
    update
} from './update.js';
import {
    WebGLRenderer,
    sRGBEncoding,
    LinearEncoding,
    ACESFilmicToneMapping, //清色调
    // ReinhardToneMapping, //暗色调
    Uncharted2ToneMapping, //增强色调
    // CineonToneMapping,  //清晨色调
    LinearToneMapping, //  线性 色调增强
    NoToneMapping,
    PMREMGenerator
} from 'three';
// import Stats from '../threejs/examples/jsm/libs/stats.module.js';

// var stats;

//---------------------- 初始化渲染器 -------------------------

function initRenderer(element, outputEncoding) {
    let canvas_frame = document.querySelector(element);
    let width = window.innerWidth;
    let height = window.innerHeight;
    let renderer = new WebGLRenderer({
        antialias: true,
        alpha: true
    });
    
    renderer.setClearAlpha(0);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    switch (outputEncoding) {
        case "sRGBEncoding":
            renderer.outputEncoding = sRGBEncoding;
            break;
        case "LinearEncoding":
            renderer.outputEncoding = LinearEncoding;
            break;
        default:
            renderer.outputEncoding = LinearEncoding;
            break;
    }

    // renderer.gammaOutput = true;
    renderer.toneMapping = ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    // renderer.setClearColor(0x000000, 1);
    var pmremGenerator = new PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();
    canvas_frame.appendChild(renderer.domElement);
    return renderer;
}

// ----------------------- 实时渲染 --------------------------

function render(renderer, camera, scene) {
    if (!renderer) return;
    renderer.clear();
    renderer.render(scene, camera);
    window.renderer = renderer;
    update();
    window.requestAnimationFrame(() => {
        render(renderer, camera, scene)
    });
}

// ----------------------- 清理渲染器 ------------------------

function clearRenderer(renderer) {
    if (!renderer) return;
    renderer.dispose();
    renderer.forceContextLoss();
    renderer.context = null;
    renderer.domElement = null;
    renderer = null;
}

// ----------------------------------------------------------

export {
    initRenderer,
    render,
    clearRenderer
};