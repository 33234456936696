import { Scene, FogExp2, Fog } from 'three';

// ------------------------ 初始化场景 ---------------------------

function initScene () {
    let scene = new Scene();
    window.scene = scene;
    return scene;
}

// ---------------------------场景雾化------------------------------


export { initScene };
