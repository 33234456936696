import { PerspectiveCamera, Vector3 } from 'three';

var moving_range;

// ------------------------- 初始化摄像机 ----------------------------

function initCamera(element, { fov, near, far }) {
  let canvas_frame = document.querySelector(element);
  let width = canvas_frame.offsetWidth;
  let height = canvas_frame.offsetHeight;
  let camera = new PerspectiveCamera(fov, width / height, near, far);
  window.camera = camera;
  return camera;
}

// --------------------- 设置摄像机的初始化位置 -----------------------

function setCameraInitPos(camera, pos) {

  // 设置摄像机位置和方向
  camera.position.set(pos.x, pos.y, pos.z);
  camera.lookAt(new Vector3(0, 0, 0));

 }

// ------------------------------------------------------------------

export { initCamera, setCameraInitPos,moving_range };
