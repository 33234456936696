<template>
  <!-- 招募合伙人页面 -->
  <div class="pag-wrap" style="display:none">
    <!-- v-if="!loadingComplete"   -->
    <div class="videoBox ">
      <video class="video animate__animated animate__fadeIn" style="width:100%;" :src="videoSrc.xgcmp4" poster=""
        preload="metadata" muted="" autoplay="" playsinline="" loop="">
      </video>
    </div>
    <!-- <canvas class="canvas" style="display:none" id="pag-bk"></canvas>
    <canvas class="canvas" style="display:none" id="pag-bd"></canvas>
    <canvas class="canvas" style="display:none" id="pag-bp1"></canvas>
    <canvas class="canvas" style="display:none" id="pag-bp2"></canvas>
    <canvas class="canvas" style="display:none" id="pag-bp3"></canvas> -->

    <div>

      <div class="hide-Bt" @click="getHide">
        <img src="../assets/img/close.png">
      </div>
      <div class="pag-ui">
        <div class="bp">
          <div class="bp-list">
            <div class="bp-item  bp-active " @click="changePag('yymp4', 0)">
              <div class="bp-point" />月夜白
            </div>
            <div class="bp-item" @click="changePag('zllmp4', 1)">
              <div class="bp-point" />紫罗兰
            </div>
            <div class="bp-item" @click="changePag('xgcmp4', 2)">
              <div class="bp-point" />炫光彩
            </div>
          </div>
          <div class="bp-info">
            <div class="bp-title">
              <div class="bp-text1">{{ bp_text1 }} </div>
              <div class="bp-text2">{{ bp_text2 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { PAGInit } from 'libpag'
import bdmp4 from '../assets/MP4/bd.mp4'
import bkmp4 from '../assets/MP4/bk.mp4'
import xgcmp4 from '../assets/MP4/xgc.mp4'
import yymp4 from '../assets/MP4/yy.mp4'
import zllmp4 from '../assets/MP4/zll.mp4'

var percentageInterval = null;
// var PAG,canvas,pagView
var bpText = [
  ['镶钻三面翻转表盘( 月光白 )', '360°全覆盖式表面，通体镶满600余颗施华洛世奇钻石，折射出的光影变幻，恍若满天星光交相辉映，以此镌刻绚丽碎钻的赞美诗篇，仿佛主角立于舞台中心，享受每个角度的璀璨光芒映照。'],
  ['镶钻三面翻转表盘( 紫罗兰 )', '360°全覆盖式表面，通体镶满600余颗施华洛世奇钻石，折射出的光影变幻，恍若满天星光交相辉映，以此镌刻绚丽碎钻的赞美诗篇，仿佛主角立于舞台中心，享受每个角度的璀璨光芒映照。'],
  ['镶钻三面翻转表盘( 炫光彩 )', '360°全覆盖式表面，通体镶满600余颗施华洛世奇钻石，折射出的光影变幻，恍若满天星光交相辉映，以此镌刻绚丽碎钻的赞美诗篇，仿佛主角立于舞台中心，享受每个角度的璀璨光芒映照。'],
];
var bkText = [
  ['两款便捷拆卸表带', '16mm镶钻表扣，轻巧时尚，彰显简约之美。']
];
var bdText = [
  ['镶钻表带', '两款定制款表带——爱马仕丝绢和鳄鱼皮，自由变换随心搭配，组合出不同气质的美学风格。']
]
var pagText = [bpText, bkText, bdText];
export default {
  name: 'PagCanvas',
  props: {
    msg: String,
  },
  data() {
    return {
      loadingComplete: true,
      pagList: new Array(),
      bp_text1: bpText[0][0],
      bp_text2: bpText[0][1],
      videoSrc: { xgcmp4, yymp4, zllmp4 }
    }
  },
  mounted() {
    let that = this;
    // setTimeout(() => {
    //   that.initPagList();
    // }, 2000);
  },
  methods: {
    initPagList() {
      this.pagInit("./static/pag/bdv01.pag", 'pag-bd');
      this.pagInit("./static/pag/bkv01.pag", 'pag-bk');
      this.pagInit("./static/pag/yy.pag", 'pag-bp1');
      this.pagInit("./static/pag/yy.pag", 'pag-bp2');
      this.pagInit("./static/pag/zll.pag", 'pag-bp3');
    },
    async pagInit(url, name) {
      // 实例化 PAG
      let PAG = await window.libpag.PAGInit();
      // Initialize ffavc webassembly module.
      // const FFAVC = await window.ffavc.FFAVCInit();
      // const ffavcDecoderFactory = new FFAVC.FFAVCDecoderFactory();
      // PAG.registerSoftwareDecoderFactory(ffavcDecoderFactory);
      // 获取 PAG 素材数据
      let buffer = await fetch(url).then((response) => response.arrayBuffer());
      // 加载 PAG 素材为 PAGFile 对象
      let pagFile = await PAG.PAGFile.load(buffer);
      // 将画布尺寸设置为 PAGFile的尺寸
      let canvas = document.getElementById(name);
      let canvasFrame = document.querySelector("#canvas-frame");
      canvas.width = canvasFrame.offsetWidth;
      canvas.height = canvasFrame.offsetHeight;
      // 实例化 PAGView 对象
      let pagView = await PAG.PAGView.init(pagFile, canvas);
      // 播放 PAGView
      await pagView.setRepeatCount(0);
      await pagView.play();
      this.pagList[name] = pagView;
      this.$emit('getPagList', this.pagList);

      // let that = this;
      // console.log(1)
      // PAGInit().then((PAG) => {
      //   const url = './static/pag/like.pag';
      //   fetch(url, name)
      //     .then((response) => response.blob())
      //     .then(async (blob) => {
      //       const file = new window.File([blob], url.replace(/(.*\/)*([^.]+)/i, '$2'));
      //       const pagFile = await PAG.PAGFile.load(file);
      //       let canvasFrame = document.querySelector("#canvas-frame");
      //       document.getElementById(name).width = canvasFrame.offsetWidth;
      //       document.getElementById(name).height = canvasFrame.offsetHeight;
      //       const pagView = await PAG.PAGView.init(pagFile, '#' + name);
      //       pagView.setRepeatCount(0);
      //       await pagView.play();
      //       console.log('play');
      //       that.pagList[name] = pagView;
      //       that.$emit('getPagList', this.pagList);
      //     });
      // });
    },

    changePag(show_Name, num) {
      //show title list
      let bpList = document.getElementsByClassName("bp-list")[0].children;
      for (let i = 0; i < bpList.length; i++)
        bpList[i].className = i == num ? "bp-item bp-active" : "bp-item";
      document.getElementsByClassName('bp-text1')[0].innerText = bpText[num][0];
      document.getElementsByClassName('bp-text2')[0].innerText = bpText[num][1];

      // show canvas
      // for (let i of document.getElementsByClassName('canvas')) {
      //   i.style.display = 'none'
      // }
      // document.getElementById(show_Name).style.display = 'inline';

      document.getElementsByTagName("video")[1].style.width = '0';
      document.getElementsByTagName("video")[1].src = this.videoSrc[show_Name];
      setTimeout(function () { document.getElementsByTagName("video")[1].style.width = '100%' }, 1000);

      let vo = document.getElementsByClassName("video")[0]
      vo.style.display = 'none'
      vo.addEventListener("loadstart", function () {
        setTimeout(function () {
          vo.style.display = 'inline'
          document.getElementsByClassName("video")[0].className = 'video animate__animated animate__fadeIn'
        }, 1000)
      })
    },

    getHide(view) {
      // window.scene.visible=true;
      // document.getElementById('canvas-frame').children[0].style.display='block'
      // document.getElementsByTagName("video")[1].play();
      document.getElementsByClassName('pag-wrap')[0].style.display = 'none'
    },
    changeText(num) {
      let text = pagText[num];
      // this.bp_text1 = text[0][0];
      // this.bp_text2 = text[0][0];
      document.getElementsByClassName('bp-text1')[0].innerText = text[0][0];
      document.getElementsByClassName('bp-text2')[0].innerText = text[0][1];
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pag-wrap {
  position: absolute;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}

.hide-Bt {
  position: absolute;
  right: 4%;
  top: 4%;
  z-index: 10;
}

.bp {
  color: #fff;
}

.bp-list {
  position: absolute;
  top: 6%;
  left: 10%;
}

.bp-item {
  letter-spacing: 5px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #606060;
  display: flex;
}

.bp-list div:active {
  color: #fff;
}

.bp-point {
  width: 10px;
  height: 10px;
  background: #FFFFFF;
  opacity: 1;
  margin-right: 10px;
  margin-top: 2px;
  border-radius: 5px;
  visibility: hidden;
}

.bp-active {
  color: #fff;
}

.bp-active div {
  visibility: initial;
}

.bp-info {
  display: flex;
  justify-content: center;
}

.bp-title {
  position: absolute;
  bottom: 10%;
  width: 300px;
  text-align: left;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #919191;
  padding: 20px;
}

.bp-text1 {
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 10px;
}

.bp-text2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
</style>
