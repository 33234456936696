<template>
  <div>
    <!-- 招募合伙人页面 -->
    <div class="partners-wrap partners-show  animate__animated animate__fadeIn" id="loading" style="z-index:9">
      <!-- v-if="!loadingComplete"   -->
      <div class="" style="text-align: center">
        <div class="span viewBox">
          <img style="width:100%;" src='../assets/p-bg.png'>
          <!-- <div class="text_group">
            <div class="text1 " >【招募合伙人 x 壹链独物】</div>
          </div> -->
        </div>
      </div>

    </div>
    <div class="partners-show" >
      <div class="pageBox">
        <!-- 二维码 -->
        <div class="bg animate__animated animate__fadeIn" v-show="isBgShow" @click="reback"></div>
        <div class="wechatPage" v-show="isQRcodeShow">
          <div class="wechatLogo">
            <div class="QR_top">
              <img src="../assets/img/QRlogo.png" />
              <div>
                <div class="QR_top_text1"> Dior x 壹链独物 </div>
                <div class="QR_top_text2"> IP联名款梦幻舞台系列腕表 </div>
              </div>
            </div>
          </div>
          <div class="QRcode animate__animated animate__fadeIn">
            <img src="../assets/img/QRcode.png" />
            <div class="QR_top_text3"> 长按添加您的专属商务 </div>
          </div>
        </div>

        <!-- 信息登记 -->
        <div class="phonePage animate__animated animate__fadeIn" v-show="isPhoneShow">
          <!-- 登录名称 -->
          <div class="form-phone">
            <div class="username-group">
              <div class="soutu-btn icon1"></div>
              <!-- <label for="username">登录名称</label> -->
              <input class="form-control " id="username" placeholder="请输入登录名称" autocomplete="off" />
              <!-- v-model.trim="username" -->
              <div class="form-control-append" @click="getVerification"> {{ verificationText }} </div>
            </div>
          </div>
          <div class="username-tips">{{ userTips }}</div>
          <!-- 登录验证码 Verification Code-->
          <div class="form-password">
            <div class="soutu-btn icon2"></div>
            <!-- <label for="password">登录密码</label> -->
            <input type="text" class="form-control" id="password" placeholder="请输入验证码" />
            <!-- v-model.trim="password"  type="password"-->
          </div>
          <!-- 登录和重置按钮 -->
          <button type="button" class="sumitBt" @click="login">提交</button>
        </div>

        <!-- 提交成功 -->
        <div class="sumitedPage" v-show="isSumited">
          <div class="sumited">
            <img src="../assets/img/sumited.png" />
            <div class="sumited_text"> {{ sumitLog }} </div>
          </div>
        </div>

      </div>
      <div class="back-Bt" @click="getBack">
        <img src="../assets/img/back.png">
      </div>
      <div class="bottom-item">
        <div class="item-box" @click="getWechat">
          <img class="Wechat_icon" src="../assets/img/wechat.png">
          <span>商务洽谈</span>
        </div>
        <div class="item-box" @click="getPhone">
          <img class="Phone_icon" src="../assets/img/phone.png">
          <span>登记信息</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css';

var percentageInterval = null
var valid = true;
export default {
  name: 'Partners',
  props: {
    msg: String
  },
  data() {
    return {
      isBgShow: false,
      isQRcodeShow: false,
      isPhoneShow: false,
      isSumited: false,
      verificationText: "获取验证码",
      userTips: ' ',
      isGetVerification: true,
      isSumiterLoad: true,
      sumitLog: '提交成功'
      // isShow: true,
    }
  },
  mounted() {
    console.log('test')
  },
  methods: {
    reback() {
      if (this.isSumited) {
        this.isBgShow = true;
        this.isQRcodeShow = false;
        this.isPhoneShow = true;
        this.isSumited = false;
      } else if (!this.isSumited) {
        this.isBgShow = false;
        this.isQRcodeShow = false;
        this.isPhoneShow = false;
        this.isSumited = false;
      }
    },
    getWechat() {
      console.log('微信')
      this.isBgShow = true;
      this.isQRcodeShow = true;
      this.isPhoneShow = false;
    },
    getPhone() {
      this.isBgShow = true;
      this.isPhoneShow = true;
      this.isQRcodeShow = false;
    },
    getBack() {
      document.getElementsByClassName("partners-show")[0].style.cssText="display:none";
      document.getElementsByClassName('partners-wrap')[0].className = 'partners-wrap partners-show animate__animated animate__fadeOut'
    },

    // 获取验证码 
    getVerification() {
      let value = document.getElementById('username').value;
      if (valid) {
        valid = false;
        setTimeout(() => {
          valid = true;
          this.validatePhone(value)
        }, 200)
      }
    },

    timer_muntie(e) {
      if (this.isGetVerification) {
        this.isGetVerification = false;
        let sum = 10;
        let that = this;
        let set = setInterval(function () {
          sum--;
          that.verificationText = sum;
          if (sum == 0) {
            that.verificationText = "获取验证码";
            that.isGetVerification = true;
            clearInterval(set);
          }
        }, 1000);
      }
    },

    validatePhone(value) {
      const reg = /^[1][3-9][0-9]{9}$/;
      if (value == '' || value == undefined || value == null) {
        this.userTips = '电话号码不为空';
      } else {
        if ((!reg.test(value)) && value != '') {
          this.userTips = '请输入正确的电话号码';
        } else {
          this.timer_muntie();
          this.userTips = '  ';
          // 发送验证码接口
          0
          fetch('https://test-watch-api.duwushoufa.com/sms/code?phone=' + value).then(function (response) {
            return response.json();
          }).then(function (myJson) {
            console.log(myJson.code);
            console.log(myJson.msg);
          });
        }
      }
    },

    login() {
      let value = document.getElementById('username').value;
      let code = document.getElementById('password').value;
      let that = this;
      const reg = /^[1][3-9][0-9]{9}$/;
      if (value == '' || value == undefined || value == null) {
        this.userTips = '电话号码不为空';
      } else {
        if ((!reg.test(value)) && value != '') {
          this.userTips = '请输入正确的电话号码';
        } else {
          this.timer_muntie();
          this.userTips = '  ';
          // 发送验证码接口
          0
          fetch('https://test-watch-api.duwushoufa.com/sms/submit?phone=' + value + '&code=' + code).then(function (response) {
            return response.json();
          }).then(function (myJson) {
            if (myJson.code == 0) {
              console.log(myJson.msg);
              that.isPhoneShow = false;
              that.isSumited = true;
              that.sumitLog = '提交成功';
            } else {
              that.isPhoneShow = false;
              that.isSumited = true;
              that.sumitLog = '验证码错误，提交失败';
            }
          });
        }
      }
    },

    debounce(fn, delay) {

      return function () {
        if (!valid) {
        } else {
          valid = false
          setTimeout(() => {
            valid = true;
          }, delay)

        }
      }
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.partners-show{
  display: none;
}
.partners-wrap {
  width: 100%;
  height: 100%;
  background-color: rgb(53, 52, 52);
  /* background: url('../assets/p-bg.png')no-repeat; */
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  overflow-y: auto;
  /* background: radial-gradient(circle,rgb(230, 227, 227),rgb(0, 0, 0)); */
}

.bottom-item {
  width: 100%;
  position: absolute;
  height: 100px;
  bottom: 0%;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.back-Bt {
  position: absolute;
  left: 4%;
  top: 4%;
  z-index: 10;
}

.viewBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text_group {
  position: absolute;
  bottom: 20%;
  color: #fff;
}

.text1 {
  font-size: 7vw;
  font-weight: 550;
  color: #fff;
}

.item-box {
  width: 100px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.item-box img {
  width: 26px;
  margin-bottom: 5px;
}

.pageBox {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.wechatPage {
  position: absolute;
  top: 30%;
  width: 270px;
  max-width: 270px;
  height: 330px;
  max-height: 330px;
  background: url('../assets/img/QRbg.png');
  margin-bottom: 10vh;
  z-index: 10;
}

.phonePage {
  position: absolute;
  top: 30%;
  width: 270px;
  max-width: 270px;
  height: 2600px;
  max-height: 260px;
  background: linear-gradient(144deg, #FFFFFF 67%, #DBF1FF 100%);
  border-radius: 12px 12px 12px 12px;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.sumitedPage {
  width: 270px;
  max-width: 270px;
  height: 140px;
  max-height: 140px;
  background: linear-gradient(144deg, #FFFFFF 67%, #DBF1FF 100%);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.sumited_text {
  font-size: 14px;
  font-weight: 550;
}

.QR_top {
  display: flex;
  justify-content: center;
  position: relative;
  top: 18px;
}

.QR_top_text1 {
  text-align: left;
  color: #fff;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
}

.QR_top_text2 {
  text-align: left;
  color: #fff;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 300;
}

.QR_top_text3 {
  text-align: center;
  color: #000;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
}

.QRcode {
  margin-top: 60px;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000ba;
  left: 0;
  top: 0;
  z-index: 9;
}


.form-control {
  padding-left: 20px;
}

.form-phone input {
  width: 140px;
  height: 40px;
}

.form-password input {
  margin-bottom: 30px;
  width: 210px;
  height: 40px;
}

.soutu-btn {
  position: absolute;
  width: 20px;
  height: 20px;
}

.icon1 {
  margin: 10px 0px 0 2px;
  background: url(../assets/img/phoneicon.png) no-repeat;
}

.icon2 {
  margin: 14px 0px 0 5px;
  background: url(../assets/img/verification.png) no-repeat;
}

.username-group {
  display: inline-table;
}

.username-tips {
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  margin-left: 40px;
  font-size: 12px;
  color: red;
}

.form-control-append {
  background-color: #000;
  color: #fff;
  font-size: 12px;
  vertical-align: middle;
  display: table-cell;
  border-radius: 0px 4px 4px 0px;
  width: 70px;
}

.sumitBt {
  width: 170px;
  height: 40px;
  background: #000000;
  color: #fff;
  border-radius: 71px 71px 71px 71px;
}
</style>
