<template>
  <div style="background:#000">
    <!-- 加载 Loading 区 -->
    <div class="loading-wrap" id="loading"  >
      <!-- style="display:none" -->
      <!-- v-if="!loadingComplete"   -->
      <div class="loadingPage">
        <div class="" style="text-align: center">
          <!-- <img class="loadingLogo" src="../../assets/loading/loadingLogo.png" /> -->
          <div class="span timerBox">
            <div class="text_group">
              <div class="text1">【Dior x 壹链独物】</div>
              <div class="text2">IP联名梦幻舞台系列腕表</div>
            </div>
            <div class="timer"></div>
            <div class="percentage">{{ percentageNum + '%' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var percentageInterval = null
export default {
  name: 'Loading',
  props: {
    msg: String
  },
  data() {
    return {
      loadingComplete: true,
      percentageNum: 10,
    }
  },
  mounted() {
    percentageInterval = setInterval(() => {
      if (this.percentageNum >= 95) {
        clearInterval(percentageInterval);
      }
      else {
        this.percentageNum += 3;
      }
    }, 400)
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading-wrap {
  width: 100%;
  height: 100%;
  background-color: rgb(53, 52, 52);
  background: url('../assets/bg.png')no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background: radial-gradient(circle,rgb(230, 227, 227),rgb(0, 0, 0)); */
  z-index: 10;
}

.timerBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.timer {
  width: 24px;
  height: 24px;
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 2px #fff;
  border-radius: 50%;
  /* margin: 38px auto; Not necessary- its only for layouting */
  position: absolute;
  bottom: 10%;
}

.timer:after,
.timer:before {
  position: absolute;
  content: "";
  background-color: #fff;
}

.timer:after {
  width: 10px;
  height: 2px;
  top: 11px;
  left: 11px;
  -webkit-transform-origin: 1px 1px;
  -moz-transform-origin: 1px 1px;
  transform-origin: 1px 1px;
  -webkit-animation: minhand 2s linear infinite;
  -moz-animation: minhand 2s linear infinite;
  animation: minhand 2s linear infinite;
}

.timer:before {
  width: 8px;
  height: 2px;
  top: 11px;
  left: 11px;
  -webkit-transform-origin: 1px 1px;
  -moz-transform-origin: 1px 1px;
  transform-origin: 1px 1px;
  -webkit-animation: hrhand 8s linear infinite;
  -moz-animation: hrhand 8s linear infinite;
  animation: hrhand 8s linear infinite;
}

@-webkit-keyframes minhand {
  0% {
    -webkit-transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}

@-moz-keyframes minhand {
  0% {
    -moz-transform: rotate(0deg)
  }

  100% {
    -moz-transform: rotate(360deg)
  }
}

@keyframes minhand {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes hrhand {
  0% {
    -webkit-transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}

@-moz-keyframes hrhand {
  0% {
    -moz-transform: rotate(0deg)
  }

  100% {
    -moz-transform: rotate(360deg)
  }
}

@keyframes hrhand {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.percentage {
  position: absolute;
  bottom: 6%;
  color: #fff;
}

.text_group {
  position: absolute;
  bottom: 20%;
  color: #fff;
}
.text1 {
  font-size: 7vw;
  font-weight: 550;
  color: #fff;
}
.text2 {
  font-size: 6vw;
  font-weight: 500;
  color: #fff;
}
</style>
