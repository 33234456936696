
// 执行队列
var queue = [];

// ----------------  在渲染器每帧执行 --------------------

function update () {
    for (let i = 0; i < queue.length; i++) {
        queue[i]();
    }
}

// --------------  添加函数队列进 Update -----------------

function addToUpdate (fn) {
    queue.push(fn);
}

// ----------------- 在队列删除指定函数 -------------------

function removeInUpdate (fn) {
    let index = queue.indexOf(fn);
    if (index > -1) {
        queue.splice(index, 1);
    }
}

// -------------------------------------------------------
// 临时添加
 function  addrotation(obj) {
     obj.rotation.z+=100;
 }
export { update, addToUpdate, removeInUpdate,addrotation };